<template>
  <div class="cx-404">404
  </div>
</template>
<script>
export default {
  data () {
    return {
    }
  },
  props: {
    normal: {
      type: String,
      default: '1.png'
    },
    active: {
      type: String,
      default: '1-1.png'
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
}
</script>
<style scoped lang="stylus">
.cx-404
  font-size 60px
  color $c1
  text-align center
</style>